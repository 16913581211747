import styles from 'pages/PaymentPage/ui/Accordion/Accordion.module.scss';
import { VENDORS_ICONS_POPULAR } from 'shared/utils/currency';
import { SwitchereComponent } from 'widgets/SwitchereComponent/SwitchereComponent';

type SwitchereWrapperType = {
  switchereLink: string;
  priority: string;
  id: string;
  setId: (id: string) => void;
};

export const SwitchereWrapper = (props: SwitchereWrapperType) => {
  const { switchereLink, priority, id, setId } = props;

  return (
    <>
      {switchereLink && priority === 'switchere' && (
        <li className={styles.accordion__item}>
          <h2 className={styles.accordion__subtitle} onClick={() => setId('3')}>
            <img className={styles.accordion__img} src={VENDORS_ICONS_POPULAR['card']} alt="" />
            <p className={styles.accordion__text}>PAY BY CARD</p>
            <button className={styles.accordion__button}>click</button>
          </h2>
          <div className={`${styles.accordion__collapsed} ${'3' === id ? styles.accordion__open : ''}`}>
            <div className={styles.accordion__switchere}>
              <SwitchereComponent switchereLink={switchereLink} id={id} />
            </div>
          </div>
        </li>
      )}
    </>
  );
};
