import {CryptoName} from "shared/utils/currency";

// export const CRYPTO_LINKS: Record<CryptoName, string> = {
//     btc: `bitcoin:${wallet}?amount=${amount}&label=btc`,
//     eth: `ethereum:${wallet}?amount=${amount}`,
//     usdt: 'USDT',
//     usdc: `usdc:${wallet}?amount=${amount}`,
//     ltc: `litecoin:${wallet}?amount=${amount}`,
//     bch: `bitcoincash:${wallet}?amount=${amount}`,
//     zec: `zcash:${wallet}?amount=${amount}`,
//     qtum: `qtum:${wallet}?amount=${amount}`,
//     btg: `bitcoingold:${wallet}?amount=${amount}`,
//     dash: `dash:${wallet}?amount=${amount}`,
//     trx: `trx:${wallet}?amount=${amount}`,
//     usdt_trc20: `tron:${wallet}?amount=${amount}&token=TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t`,
//     bscusd: `bscusd`,
//     bnb: `binancecoin:${wallet}?amount=${amount}`,
//     busd: `busd:${wallet}?amount=${amount}`
// };

// bitcoin:0x85c8dD6513124d4B76b65628AD02C725890d0B67?amount=21.053&label=btc
//     ethereum:0xb794f5ea0ba39494ce839613fffba74279579268@1?amount=1
// dash:Xcu5iYBH3szP744sQ1RUp3JHTVFHrFVdYu?amount=0.11

//как эфир eth bnb // как usdt usdc bcusd busd как eth + smart contract
//"litecoin:[number wallet]?amount=[amount]"

export const generateQrCode = (code, wallet, amount) => {

    const CRYPTO_LINKS = {
        btc: `bitcoin:${wallet}?amount=${amount}&label=btc`,//trust открывает сумму кошелек++
        eth: `ethereum:${wallet}@1?value=${amount}e18`,//+
        usdt: `${wallet}?amount=${amount}`,//trust открывает сумму кошелек
        usdc: `${wallet}?amount=${amount}`,// -
        ltc: `litecoin:${wallet}?amount=${amount}`,// //trust внутри неверный адресс кошелька сумма есть ++
        bch: `${wallet}?amount=${amount}&label=bch`,//trust открывает сумму кошелек
        zec: `zcash:${wallet}?amount=${amount}`,// trust внутри неверный адресс кошелька сумма есть ++
        qtum: `qtum:${wallet}?amount=${amount}`,//trust внутри неверный адресс кошелька сумма есть ++
        btg: `bitcoingold:${wallet}?amount=${amount}&label=btg`,//-
        dash: `dash:${wallet}?amount=${amount}`,//-
        trx: `trx:${wallet}?amount=${amount}`,//trust открывает сумму кошелек
        usdt_trc20: `tron:${wallet}?amount=${amount}`,//&token=TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t ++ trust внутри
        bscusd: `binance:${wallet}`,//-
        bnb: `binance:${wallet}`,//trust открывает сумму кошелек
        busd: `binance:${wallet}`// -
    }


    return CRYPTO_LINKS[code]
}

export const generateLinksForBitcoinFork = (code, wallet, amount) => {

    const CRYPTO_LINKS = {
        btc: `bitcoin:${wallet}?amount=${amount}`,
        ltc: `litecoin:${wallet}?amount=${amount}`,
        bch: `bitcoincash:${wallet}?amount=${amount}`,
        zec: `zcash:${wallet}?amount=${amount}`,
        qtum: `qtum:${wallet}?amount=${amount}`,
        btg: `bitcoingold:${wallet}?amount=${amount}`,
        dash: `dash:${wallet}?amount=${amount}`
    }

    return CRYPTO_LINKS[code]
}

// export const exceptionForWindows = (link) => {
//     try {
//         window.location.href = 'mailto:anjele@bk.ru'      //`bitcoin:${address}?amount=${amount}`;
//         setTimeout(function () {      // Если код доходит до этого места, возможно, у пользователя нет обработчика для протокола bitcoin
//             alert('Необходимо установить приложение для работы с Bitcoin.');
//         }, 1000);
//     } catch (e) {
//         console.error('Ошибка при попытке перейти по ссылке Bitcoin: ', e);    // Обработка ошибки, например, показ сообщения пользователю
//     }
// }

