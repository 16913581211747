import styles from 'pages/PaymentPage/ui/Accordion/Accordion.module.scss';
import qr from 'app/assets/icons/qr.svg';
import copy from 'app/assets/icons/copy.svg';
import { SELECT_CRYPTO_CODES } from 'shared/utils/currency';
import QRCode from 'react-qr-code';
import { generateQrCode } from 'shared/utils/qrCode';
import hashImg from 'app/assets/icons/hash.svg';
import { type InvoiceResponseType } from 'shared/types/types';
import { useWindowResize } from 'shared/utils/useWindowResize';
import walletStyles from './WalletWrapper.module.scss';

interface WalletWrapperType {
  id: string;
  setId: (id: string) => void;
  dateInvoiceResponse: InvoiceResponseType;
  copyBuffer: (text: string) => void;
  hashInput: string;
  dataUser: any;
  handlerChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onSubmitHash: (e: React.FormEvent<HTMLFormElement>) => void;
}

export const WalletWrapper = (props: WalletWrapperType) => {
  const { id, setId, dateInvoiceResponse, copyBuffer, hashInput, dataUser, handlerChange, onSubmitHash } = props;

  const size = useWindowResize();
  const wallet =
    size.width < 475
      ? `${dateInvoiceResponse.walletAddress.slice(0, 10)}...${dateInvoiceResponse.walletAddress.slice(-10)}`
      : dateInvoiceResponse.walletAddress;

  return (
    <li className={styles.accordion__item}>
      <h2
        className={styles.accordion__subtitle}
        onClick={() => {
          setId('1');
        }}
      >
        <img className={styles.accordion__img} src={qr} alt="" />
        <p className={styles.accordion__text}>Scan QR code</p>
        <button className={styles.accordion__button}>click</button>
      </h2>
      <div className={`${styles.accordion__collapsed} ${id === '1' ? styles.accordion__open : ''}`}>
        <div className={styles.accordion__qr}>
          <h4 className={styles.accordion__subhead}>Wallet address</h4>
          <div className={styles.accordion__address}>
            <p className={styles.accordion__wallet}>{wallet}</p>
            <button
              className={styles.accordion__buffer}
              onClick={() => {
                copyBuffer(dateInvoiceResponse.walletAddress);
              }}
            >
              <img className={styles.accordion__copy} src={copy} alt="" />
            </button>
          </div>
          <div className={styles.accordion__invoice}>
            <p className={styles.accordion__amount}>
              <span className={styles.accordion__value}>{dateInvoiceResponse.amountRequiredUnit}</span>
              <span className={styles.accordion__currency}>
                {SELECT_CRYPTO_CODES[dateInvoiceResponse.codeRequested]}
              </span>
            </p>
            <button
              className={styles.accordion__buffer}
              onClick={() => {
                copyBuffer(dateInvoiceResponse.amountRequiredUnit);
              }}
            >
              <img className={styles.accordion__copy} src={copy} alt="" />
            </button>
          </div>
          <div className={styles.accordion__code}>
            <QRCode
              className={styles.accordion__qrcode}
              value={generateQrCode(
                dateInvoiceResponse.codeRequested,
                dateInvoiceResponse.walletAddress,
                dateInvoiceResponse.amountRequiredUnit
              )}
            />
          </div>
          <p className={styles.accordion__warning}>
            Sending funds to the wrong network
            <br />
            or token can result in the loss of funds.
          </p>
        </div>
        {dataUser.type === 'invoice_type_user' && (
          <form onSubmit={onSubmitHash}>
            <div className={walletStyles.accordion__user}>
              <div className={walletStyles.textBox}>
                <h3 className={walletStyles.hashTitle}>Confirm payment</h3>
                <p className={walletStyles.subTitle}>
                  Please enter the Hash ID number to confirm your crypto transaction.
                </p>
              </div>
              <input
                className={walletStyles.hashInput}
                placeholder="Enter Hash ID/TXN Hash"
                type="text"
                value={hashInput}
                onChange={handlerChange}
              />
              <button className={walletStyles.hashSubmitBtn} type="submit">
                Confirm
              </button>
            </div>
          </form>
        )}
      </div>
    </li>
  );
};
