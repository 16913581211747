import styles from "pages/PaymentPage/ui/Accordion/Accordion.module.scss";
import {VENDORS_ICONS_POPULAR} from "shared/utils/currency";
import {MoonPayComponent} from "widgets/MoonPayComponent/MoonPayComponent";

type MoonPayWrapperType = {
    moonPayLink: any
    priority: string
    id: string
    setId: (id: string) => void
}

export const MoonPayWrapper = (props: MoonPayWrapperType) => {

    const {moonPayLink, priority, id, setId} = props
    return (
        <>
            {moonPayLink && priority === 'moonpay' &&
            <li className={styles.accordion__item}>
                <h2 className={styles.accordion__subtitle} onClick={() => setId('5')}>
                    <img className={styles.accordion__img} src={VENDORS_ICONS_POPULAR['card']} alt=""/>
                    <p className={styles.accordion__text}>PAY BY CARD</p>
                    <button className={styles.accordion__button}>click</button>
                </h2>
                <div className={`${styles.accordion__collapsed} ${'5' === id ? styles.accordion__open : ''}`}>
                    <div className={styles.accordion__switchere}>
                        <MoonPayComponent id={id} moonPayLink={moonPayLink}/>
                    </div>
                </div>
            </li>
            }
        </>
    )
}
