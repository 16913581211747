import { ChangeEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { InvoiceResponseType } from 'shared/types/types';
import { changePayment } from 'shared/utils/changepayment';
import { MoonPayWrapper } from 'pages/PaymentPage/ui/Accordion/MoonPayWrapper';
import { OnRampMoneyWrapper } from 'pages/PaymentPage/ui/Accordion/OnRampMoneyWrapper';
import { SwitchereWrapper } from 'pages/PaymentPage/ui/Accordion/SwitchereWrapper';
import { ConnectWrapper } from 'pages/PaymentPage/ui/Accordion/ConnectWrapper';
import { WalletWrapper } from 'pages/PaymentPage/ui/Accordion/WalletWrapper';
import styles from './Accordion.module.scss';

type AccordionType = {
  dateInvoiceResponse: InvoiceResponseType;
  setHash: (hash: string) => void;
  setNotification: (notification: string) => void;
  dataUser: any;
  hash: string;
  vendors: any;
};

export const Accordion = memo((props: AccordionType) => {
  const { dateInvoiceResponse, setHash, setNotification, dataUser, hash, vendors } = props;
  const [id, setId] = useState('');
  const [hashInput, setInput] = useState<string>('');
  const [hashAutoInput, setHashAutoInput] = useState<string>('');
  const [switchereLink, setSwitchereLink] = useState<string>('');
  const [moonPayLink, setMoonPayLink] = useState<string>('');
  const [onRampMoneyLink, setOnRampMoneyLink] = useState<string>('');
  const [priority, setPriority] = useState('switchere');
  const [accordionQueue, setAccordionQueue] = useState('');

  const copyBuffer = (text: string) => {
    navigator.clipboard.writeText(text).then(
      function () {
        setNotification('The text has been copied');
      },
      function (err) {
        console.error('Error copy text: ', err);
      }
    );
  };

  useEffect(() => {
    if (vendors) {
      const createdLinks = changePayment(vendors);

      if (createdLinks.hasOwnProperty('moonpay') && createdLinks.hasOwnProperty('switchere')) {
        if (createdLinks.moonpay.rate > createdLinks.switchere.rate) {
          setMoonPayLink(createdLinks.moonpay.link);
          setPriority('moonpay');
        } else {
          setSwitchereLink(createdLinks.switchere.link);
          setPriority('switchere');
        }
      }
      if (createdLinks.hasOwnProperty('moonpay') && !createdLinks.hasOwnProperty('switchere')) {
        setMoonPayLink(createdLinks.moonpay.link);
        setPriority('moonpay');
      }
      if (!createdLinks.hasOwnProperty('moonpay') && createdLinks.hasOwnProperty('switchere')) {
        setSwitchereLink(createdLinks.switchere.link);
        setPriority('switchere');
      }
      if (createdLinks.hasOwnProperty('onrampmoney')) {
        setOnRampMoneyLink(createdLinks.onrampmoney.link);
      }
    }
  }, [vendors]);

  const dataWithLocalStorage = () => {
    return localStorage.getItem(`${dataUser.txId}`)
      ? JSON.parse(localStorage.getItem(`${dataUser.txId}`) as string)
      : {};
  };

  useEffect(() => {
    if (vendors) {
      if (dataWithLocalStorage().vendor) {
        if (dataWithLocalStorage().vendor === 'onrampmoney') {
          setId('4');
        }
        if (dataWithLocalStorage().vendor === 'switchere') {
          setId('3');
        }
        if (dataWithLocalStorage().vendor === 'moonpay') {
          setId('5');
        }
      } else {
        setId('1');
      }
    }
  }, [vendors, localStorage.getItem(`${dataUser.txId}`)]);

  const handlerChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.currentTarget.value);
  }, []);

  // const handlePaste = useCallback((event: React.ClipboardEvent<HTMLInputElement>) => {
  //   event.preventDefault();
  //   const pastedData = event.clipboardData.getData('text');
  //   setInput(pastedData);
  // }, []);

  const handlerChangeAutoInput = (e: ChangeEvent<HTMLInputElement>) => {
    setHashAutoInput(e.currentTarget.value.trim());
  };

  const onSubmitHash = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (hashInput) {
        setHash(hashInput);
        setNotification('Your hash has been accepted for verification');
      } else {
        setNotification('Empty value!');
      }
    },
    [hashInput, setHash, setNotification]
  );

  const handleKeyPressAuto = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (hashInput) {
        setHash(hashInput);
        setNotification('Your hash has been accepted for verification');
      } else {
        setNotification('Empty value!');
      }
    }
  };

  const autoSetInputValue = () => {
    if (hashAutoInput) {
      setNotification('Your hash has been accepted for verification');
      setHash(hashAutoInput);
      return;
    }
    if (hash) {
      setNotification('Your hash has been accepted for verification');
      setHash(hash);
    } else {
      setNotification('Empty value!');
    }
  };

  const accordion = [
    {
      name: 'wallet',
      wrapper: (
        <WalletWrapper
          id={id}
          setId={setId}
          dateInvoiceResponse={dateInvoiceResponse}
          copyBuffer={copyBuffer}
          hashInput={hashInput}
          dataUser={dataUser}
          handlerChange={handlerChange}
          onSubmitHash={onSubmitHash}
        />
      )
    },
    {
      name: 'connect',
      wrapper: (
        <ConnectWrapper
          id={id}
          setId={setId}
          dateInvoiceResponse={dateInvoiceResponse}
          copyBuffer={copyBuffer}
          dataUser={dataUser}
          hash={hash}
          autoSetInputValue={autoSetInputValue}
          handlerChangeAutoInput={handlerChangeAutoInput}
          handleKeyPressAuto={handleKeyPressAuto}
          hashAutoInput={hashAutoInput}
          setHash={setHash}
        />
      )
    },
    {
      name: 'switchere',
      wrapper: <SwitchereWrapper switchereLink={switchereLink} priority={priority} id={id} setId={setId} />
    },
    {
      name: 'onrampmoney',
      wrapper: <OnRampMoneyWrapper onRampMoneyLink={onRampMoneyLink} id={id} setId={setId} vendors={vendors} />
    },
    {
      name: 'moonpay',
      wrapper: <MoonPayWrapper moonPayLink={moonPayLink} priority={priority} id={id} setId={setId} />
    }
  ];

  const sortedAccordion = useMemo(() => {
    if (!dataWithLocalStorage().vendor) {
      return accordion;
    } else {
      return accordion.sort((a, b) => {
        if (a.name === dataWithLocalStorage().vendor) return -1;
        if (b.name === dataWithLocalStorage().vendor) return 1;
        return 0;
      });
    }
  }, [vendors, accordion]);

  return (
    <div className={styles.wrapper}>
      <ul className={styles.accordion}>{sortedAccordion.map((el) => el.wrapper)}</ul>
    </div>
  );
});
