import styles from "pages/PaymentPage/ui/Accordion/Accordion.module.scss";
import {VENDORS_ICONS_POPULAR} from "shared/utils/currency";
import {createVendorData, VENDORS_SHOT_NAME} from "shared/utils/changepayment";
import {OnRampComponent} from "widgets/OnRampComponent/OnRampComponent";

type OnRampMoneyWrapperType = {
    onRampMoneyLink: any
    id: string
    setId: (id: string) => void
    vendors: any
}

export const OnRampMoneyWrapper = (props: OnRampMoneyWrapperType) => {
    const {onRampMoneyLink, id, setId, vendors} = props
    return (
        <>
            {onRampMoneyLink &&
            <li className={styles.accordion__item}>
                <h2 className={styles.accordion__subtitle} onClick={() => setId('4')}>
                    <img className={styles.accordion__img} src={VENDORS_ICONS_POPULAR[createVendorData(vendors)]}
                         alt=""/>
                    <p className={`${styles.accordion__text} ${createVendorData(vendors) === 'upi' ? styles.accordion__ramp : ''}`}>{VENDORS_SHOT_NAME[createVendorData(vendors)]}</p>
                    <button className={styles.accordion__button}>click</button>
                </h2>
                <div className={`${styles.accordion__collapsed} ${'4' === id ? styles.accordion__open : ''}`}>
                    <div className={styles.accordion__switchere}>
                        <OnRampComponent id={id} onRampMoneyLink={onRampMoneyLink} setId={setId}/>
                    </div>
                </div>
            </li>
            }
        </>
    )
}
