import styles from "pages/PaymentPage/ui/Accordion/Accordion.module.scss";
import walletIcon from "app/assets/icons/wallet.svg";
import {ButtonConnect} from "pages/PaymentPage/ui/ButtonConnect/ButtonConnect";
import hashImg from "app/assets/icons/hash.svg";
import {InvoiceResponseType} from "shared/types/types";

type ConnectWrapperType = {
    id: string
    setId: (id: string) => void
    dateInvoiceResponse: InvoiceResponseType;
    copyBuffer: (text: string) => void
    setHash: any
    hash: any
    dataUser: any
    hashAutoInput: any
    handlerChangeAutoInput: any
    handleKeyPressAuto: any
    autoSetInputValue: any
}

export const ConnectWrapper = (props: ConnectWrapperType) => {
    const {id, setId, dateInvoiceResponse, copyBuffer, setHash, hash} = props
    const {dataUser, hashAutoInput, handlerChangeAutoInput, handleKeyPressAuto, autoSetInputValue} = props
    return (
        <li className={styles.accordion__item}>
            <h2 className={styles.accordion__subtitle} onClick={() => setId('2')}>
                <img className={styles.accordion__img} src={walletIcon} alt=""/>
                <p className={styles.accordion__text}>Wallet</p>
                <button className={styles.accordion__button}>click</button>
            </h2>
            <div className={`${styles.accordion__collapsed} ${'2' === id ? styles.accordion__open : ''}`}>
                <ButtonConnect
                    code={dateInvoiceResponse.codeRequested}
                    amount={dateInvoiceResponse.amountRequired}
                    amountUnit={dateInvoiceResponse.amountRequiredUnit}
                    wallet={dateInvoiceResponse.walletAddress}
                    copyBuffer={copyBuffer}
                    setHash={setHash}
                />
                {dataUser.type === 'invoice_type_user' && <div className={styles.accordion__user}>
                    <input
                        className={`${styles.accordion__connectHash} ${hash ? styles.accordion__connectHashLight : ''}`}
                        placeholder={'Paste transaction hash here...'}
                        type="text"
                        value={hashAutoInput ? hashAutoInput : hash}
                        onChange={handlerChangeAutoInput}
                        onKeyPress={handleKeyPressAuto}
                    />
                    <button className={styles.accordion__submit} onClick={autoSetInputValue}>
                        <img src={hashImg} alt=""/>
                    </button>
                </div>}
            </div>
        </li>
    )
}
